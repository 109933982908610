@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600");
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
li,
ul,
td,
div,
a,
fieldset {
  font-family: "Avenir", "Open Sans", "Helvetica Neue", "Helvetica", Helvetica,
    Arial, sans-serif;
  text-rendering: optimizeLegibility;
}

h4 {
  margin-top: 2em;
  margin-bottom: 0.5em;
}

h1 {
  margin-bottom: 30px;
  line-height: 48px;
}

body {
  background-color: #f8fafa;
}

html {
  overflow-y: scroll; /* prevent scrollbar thrashing */
}

a.view-link:hover {
  text-decoration: none;
}

/* immediate parent to main-content and subnav */
.main-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  /* padding: 50px; */
  padding-right: 8px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* central column, usually containing white card */
.main-middle {
  flex: 4;
}

/* left column, usually containing subnav */
.main-left {
  flex: 1;
  max-width: 200px;
}

.subnav {
  margin-left: 7px;
  max-width: 200px;
  position: relative;
}

.subnav a,
.subnav a:hover,
.subnav a:active {
  text-decoration: none;
}

ul.subnav li {
  margin-top: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.subnav ul {
  list-style: none;
}

@media (max-width: 767px) {
  .main-container {
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    flex-direction: column;
  }

  .main-left {
    flex: 0; /* Collapse entirely, shows only dropdown menu floated above main content*/
    width: 60px;
    position: absolute;
    right: 150px;
  }

  .main-left .dropdown {
    width: 50px;
    position: absolute;
    top: 20%;
    left: 48px;
  }

  .dropdown-toggle .caret {
    margin-left: -2px;
  }

  /* Header title and subtitle that floats nicely with submenu*/
  .main-container .header-section {
    margin-left: 60px;
    margin-top: -30px;
    padding: 3px;
  }

  .subnav-header {
    padding-left: 5px;
    border-bottom: 1px solid #efefef;
  }

  .filter-link img {
    display: none;
  }

  .hide-for-mobile {
    display: none;
  }
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.main-content.card {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.12);
}

.main-panel {
  background-color: #fff;
  padding: 30px 50px;
  margin-top: 20px;
  box-shadow: 0 1px 20px hsla(0, 0%, 0%, 0.05), 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  -webkit-transition: box-shadow 0.2s; /* Safari */
  transition: box-shadow 0.2s;
  transition-timing-function: ease-in-out;
}

/* ex: Create FUT Page */
.main-panel.main-input {
  padding: 0px;
}

a.clickable-panel:hover {
  text-decoration: none;
}

a {
  color: #11a2d2;
  cursor: pointer !important;
}

a:hover {
  color: #007297;
}

a:hover:disabled {
  cursor: pointer;
}

button:hover:disabled {
  cursor: pointer;
}

a:active {
  color: #007297;
}

a:focus {
  color: #007297;
}

a:visited {
  color: #11a2d2;
}

.control-label {
  /*   text-transform: uppercase; */
  font-weight: 600;
}

.input-group .form-control {
  z-index: 0;
  position: static;
}

.form-control,
input {
  border: 1px solid #ddd;
}

textarea,
input[type="text"] {
  -webkit-appearance: none;
}

/* input[type="checkbox"] {
  border: 1px solid #ddd;
  color: white;
  width: 10px;
  height: 10px;
} */

.form-control:focus {
  border: 0;
  border: 1px solid #aaa;
}

.form-group {
  padding: 2px 0px;
  margin-bottom: 5px;
}

.form-group label {
  margin-top: 10px;
}

div.panel-heading {
  background-color: #fff !important;
}

.pointer {
  cursor: pointer;
}

/** Navigation */

.nav-link {
  font-family: "Lato";
  font-weight: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #555 !important;
  font-size: 0.8em;
  margin: 10px;
  text-decoration: none;
  padding: 0 10px 36px 10px;
}

.nav-link.active {
  border-bottom: 3px solid #11a2d2;
  color: #11a2d2;
}

.nav-link:active,
.nav-link:focus,
.nav-link:hover,
.nav-link:active {
  text-decoration: none !important;
}

.nav-link-container {
  position: absolute;
  right: 10px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  height: 38px;
}

/** Prevent styling for bootsrap dropdowns **/
a.dropdown-toggle.btn,
a.dropdown-toggle.btn:active,
a.dropdown-toggle.btn:focus,
a.dropdown-toggle.btn:hover,
a.dropdown-toggle.top.btn,
a.dropdown-toggle.top.btn:active,
a.dropdown-toggle.top.btn:focus,
a.dropdown-toggle.top.btn:hover,
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:active .nav .open > a.active,
.nav .open > a:focus {
  background-color: transparent;
  border-bottom: 0;
  color: #777 !important;
}

.nav > li > a:focus,
.nav > li > a:hover {
  background-color: transparent;
  border-bottom: 0;
}

@media screen and (max-width: 767px) {
  .navbar-nav {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }

  .task-main-content {
    width: 100%;
    margin-top: 0 !important;
    padding: 0;
  }

  .subnav {
    position: absolute;
    right: 220px;
  }

  .nav-link {
    margin: 10px 0px !important;
  }
}

.subnav .active {
  background-color: transparent !important;
  font-weight: bold;
  color: #11a2d2;
}

.subnav a:hover {
  background-color: transparent !important;
}

.subnav a {
  color: #777;
  line-height: 10px;
  /* padding-left: 0 !important; /* line up with left edge */
}

.subnav-header {
  color: #b7b7b7;
  font-size: 13px;
  text-transform: uppercase;
  /* padding-left: 13px; */
  padding-top: 40px;
}

.subnav .glyphicon {
  font-size: 12px;
  margin: 0px 5px;
}

h3.nav-subtitle {
  font-size: 1em;
  font-weight: bold;
  margin: 15px 0px 3px 7px;
}

.navbar-toggle {
  z-index: 1;
}

.navbar .icon-bar {
  background-color: black;
}

.nav-pills > li > a {
  padding: 12px 15px;
}

.dropdown {
  z-index: 1;
}

.dropdown li.section {
  color: #111;
  border-bottom: 1px solid #efefef;
  font-size: 12px;
  margin-top: 20px;
  margin-left: 5px;
  text-transform: uppercase;
  font-size: 11px;
}

.dropdown-menu {
  margin-top: 8px;
  padding: 7px 5px;
  border: 1px solid #fafafa;
  border-radius: 7px;
}

.dropdown-menu li > a,
.dropdown-menu li > p,
.dropdown-menu li > div {
  padding: 7px 10px 5px 10px;
  margin: 2px;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  transition: color 50ms ease-in;
  /* transition: background-color 50ms ease-in; */
}

.task-sidebar a {
  color: #333;
}

.task-sidebar a:hover {
  cursor: pointer;
}

.dropdown-menu li > a:hover {
  /* color: initial; */
  /* background-color: #11a2d2 !important; */
  color: #333 !important;
  background-color: #f5f5f5 !important;
}

.dropdown-menu li > a:active {
  background-color: #efefef !important;
}

/** "active" class is auto-applied filter link is applied (@todo - rename to not conflict with css pseudoclass) */
.dropdown-menu li > a.active {
  background-color: #f6f6f6;
}

.dropdown .dropdown-menu {
  min-width: 200px;
}

.navbar-container .dropdown-menu {
  margin-top: 5px;
}

div#cmd-bar-container {
  width: 400px;
  float: left;
  position: relative;
}
@media screen and (max-width: 767px) {
  div#cmd-bar-container {
    width: 80%;
    margin-top: -54px;
  }
}

input#cmd-bar {
  font-size: 19px;
  outline: none;
  padding: 21px;
  background-color: #f6f7f7;
  border-radius: 6px;
  border: none;
  /* color: #11a2d2; */
  color: #505050;
  height: 50px;
  text-indent: 15px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  input#cmd-bar {
    margin-left: 5px;
    border-left: 0;
  }
}

input#cmd-bar:focus {
  outline: 0 none;
}
input#cmd-bar::-webkit-input-placeholder {
  /* Edge */
  color: #cccccc;
}

input#cmd-bar:-ms-input-placeholder {
  /* Internet Explorer */
  color: #cccccc;
}

input#cmd-bar::placeholder {
  color: #cccccc;
}

/** End Navigation */

/** Dialogs **/
.alert-grey {
  background-color: #eaeaea;
  border-color: #dedede;
}

.alert .close {
  top: -20px; /** override bootstrap **/
}

pre {
  overflow-x: auto;
  white-space: pre;
  background-color: #fff;
  border: 0;
}

/** clickable panels */

.hover-panel:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 25px 0px,
    rgba(0, 2, 0, 0.12) 0px 2px 4px !important;
  position: relative;
  cursor: pointer;
  -webkit-transition: margin 0.2s, box-shadow 0.2s, background-color 0.05s; /* Safari */
  transition: margin 0.2s, box-shadow 0.2s, background-color 0.05s;
  transition-timing-function: ease-in-out;
  /* margin-left: 3px; */
}

.menu-panel h2 {
  float: left;
  margin-top: 0;
}

.menu-panel .btn {
  font-size: 13px;
  margin: 0 5px;
}

.menu-panel .nav {
  display: inline-block;
  margin-left: 5px;
}

/** Tasks */
.tasks .main-panel {
  padding: 15px;
  border-radius: 0px;
}

.list-utils {
  display: flex;
  max-width: 180px;
  float: right;
}

/** @todo normalize the main-panel class **/
.create-task-panel {
  width: 600px;
}

@media screen and (max-width: 767px) {
  .create-task-panel {
    width: 95vw;
  }
}

/** Task list util bar */

/* Tasks search bar
.task-list-utils input {
  background-color: rgb(237, 237, 237);
} */

.list-container {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  padding: 30px;
  margin-top: 20px;
  /* border-radius: 4px; */
}

.list-container:first-of-type {
  margin-top: 0;
}

@media screen and (max-width: 767px) {
  .list-container {
    padding: 3px;
    width: 100%;
  }
}

.task-list-utils a {
  color: #999;
}

.task-list-utils .btn .create-fut {
  height: 45px;
  margin-top: -5px;
}

.bulk-requests {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 1px 2px;
  display: flex;
  height: 45px;
}

.bulk-progress {
  flex: 3;
  border-radius: 3px;
  background-color: #ccc;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
}

.bulk-progress-bar {
  width: 100%;
  min-width: 5%;
  transform-origin: left;
  height: 6px;
  background-color: #11a2d2;
  border-radius: 3px;
}

.bulk-status {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
}

.bulk-status .task-completed {
  color: #015a01;
  margin-right: 12px;
  white-space: nowrap;
}

.bulk-requests .task-view-errors {
  margin-left: 12px;
  white-space: nowrap;
  padding: 0 5px;
  text-transform: initial;
  font-weight: normal;
  /* font-size: 12px; */
}

.vertical-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/** Task list items */
a .tasks-list-item,
.tasks-list-item,
.people-list-item {
  padding: 12px;
  color: #2c3e50;
  border-radius: 5px;
  transition: all 0.2s ease-out;
}

a .tasks-list-item:hover,
.tasks-list-item:hover,
.people-list-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
  color: #2c3e50;
}

a .tasks-list-item:active,
.tasks-list-item:active,
.people-list-item:active {
  background-color: #efefef;
  cursor: pointer;
}

.task-main-content {
  margin-top: 35px;
}

/** End Tasks */

/**MailBots */

.help-block code {
  font-size: 11px;
}

.mailbot-settings input {
  width: 300px;
  background-color: #efefef;
  padding: 7px;
  border: 0;
  font-family: monospace;
  color: #aaa;
  font-size: 12px;
}

.mailbot-settings .sample-env {
  overflow: hidden;
  width: 100%;
  background-color: #efefef;
  padding: 15px;
  font-family: monospace;
  color: #aaa;
  font-size: 12px;
}

.mailbot-settings label {
  /*display: block;*/
  width: 130px;
  padding-top: 20px;
}

/* Main FUT settings. requires special margin for mobile submenu */
@media screen and (max-width: 767px) {
  .settings-card {
    margin-top: 50px;
  }
}

.sidebar {
  padding: 60px;
  /*padding-top: 50px;*/
}

.webhook-url {
  font-size: 11px;
  white-space: nowrap;
  margin-bottom: 10px;
}

.well {
  background-color: #f5fdff;
}

/* Footer, positioned at the bottom of the window with empty state */
#page-container {
  min-height: 100vh;
  position: relative;
}

#footer-container {
  position: absolute;
  display: flex;
  bottom: 200px;
  width: 100%;
}

.footer-msg {
  color: rgb(170, 170, 170);
  min-width: 300px;
  padding: 40px;
  margin: 40px auto;
}

.footer-msg a {
  color: rgb(170, 170, 170);
  text-decoration: underline;
}

.footer-msg img.icon {
  width: 30px;
  opacity: 0.5;
  float: left;
  margin: 0 10px;
}

/* MailBots List */

.mailbots-section-hero {
  margin-right: 8px;
}

.mailbot-list-container {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(245px, 1fr));
  grid-gap: 30px;
  justify-items: center;
  align-items: center;
}

.mailbots-list-item {
  padding: 30px;
  margin: 8px;
  width: 100%;
  height: 100%;
  min-height: 250px;
  max-height: 300px;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .mailbot-list-container {
    display: block;
  }
}

.mailbots-list-item .bottom-bar {
  position: absolute;
  bottom: 0px;
  z-index: 999;
  width: 100%;
  background-color: #f7f7f7;
  left: 0;
  height: 45px;
  padding: 10px;
}

.mailbots-list-item h2 {
  font-size: 18px;
  font-weight: bold;
}

.mailbots-list-item p {
  color: #676767;
  font-size: 14px;
}

.mailbots-icon {
  padding: 2px;
}

img.mailbots-icon.small {
  width: 60px;
}

img.mailbots-icon.x-small {
  width: 40px;
}

img.mailbots-icon.large {
  width: 100px;
  margin: 5px;
  margin-left: 0px;
  margin-bottom: 35px;
}

/* Emulator */
.email-compose {
  padding: 10px 20px;
}

.email-compose input {
  border: 0;
  border-bottom: 1px solid #efefef;
  border-radius: 0px;
}

.email-compose textarea {
  border: 0;
}

/* Response Emails */

.email-message {
  padding: 10px 0px;
  min-height: 500px;
  max-width: 600px;
  overflow: hidden;
}

.email-message ul {
  margin-left: -25px;
  overflow: none;
}

.email-message li {
  list-style: none;
  margin: 4px 0;
  padding: 0;
  white-space: nowrap;
}

.email-message .field-label {
  color: #aaa;
  margin: 3px 8px;
}

.email-message iframe.body {
  border: 0;
  /* overflow: wrap; */
  margin-left: -10px;
  border-top: 1px solid #efefef;
  padding-top: 6px;
  margin-top: 6px;
  width: 100%;
  /* height: 350px; */
}

@media screen and (max-width: 767px) {
  .email-message {
    margin: 15px;
  }
}

@media screen and (max-width: 850px) {
  .email-message {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px !important;
  }
}

/* Log Table */

.icon-green {
  color: #015a01;
}

.icon-red {
  color: #b80000;
}

.icon-orange {
  color: #e3b238;
}

.table-hover {
  cursor: pointer;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.table-hover tr.log-details:hover {
  background: #fff;
  cursor: default;
}

.table-hover thead {
  border-bottom: 1px solid #ccc;
}

.log-details {
  display: visible; /* Change this to "none" in prod. */
}

.log-details .glyphicon-repeat {
  margin-right: 5px;
}

.log-details-contents {
  border-left: 4px solid #dedede;
  padding-left: 25px;
}

.log-summary {
  overflow: hidden;
  white-space: nowrap;
}

.log-column {
  white-space: nowrap;
  min-width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expand-checkbox-container {
  width: 100%;
  float: right;
}

.nav-header {
  width: 300px;
  float: right;
  margin-top: 30px;
}

/* Buttons */

.btn {
  padding: 9px 35px;
  border-radius: 4px;
  border: 0 none;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.2s;
}

.btn:focus,
.btn.active:focus,
.btn.active:focus {
  outline: 0 none;
}

button.btn-primary,
a.btn-primary {
  background: #15aee1;
  color: #ffffff;
}

a.btn-primary.small {
  height: 30px;
  padding: 5px 40px;
}

button.btn-primary:hover,
a.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-color: #11a2d2;
}

button.btn-primary:active,
a.btn-primary:active,
.btn-primary.active {
  background: #007299 !important;
  box-shadow: none;
}

.btn.btn-default,
.btn.btn-default:hover {
  color: #11a2d2;
  background-color: transparent;
}

.btn-header {
  float: right;
}

.btn-secondary:hover {
  color: #222 !important;
}

.btn-secondary:focus {
  color: #222;
}

.btn-outlined {
  border: 1px solid rgb(211, 211, 211);
  margin: 3px;
  background-color: white;
  font-weight: normal;
  text-transform: capitalize;
  margin-bottom: 10px;
  letter-spacing: 0;
}

.btn-outlined:hover,
.btn-outlined:focus {
  color: #11a2d2;
}

/* End Buttons */

/* Sandbox */

.loader-row:hover {
  background-color: white !important;
}

.fade-in-out {
  -webkit-animation: fade 4s;
  animation: fade 4s;
  opacity: 1;
}

@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loader {
  font-size: 10px;
  margin: 10px auto;
  text-indent: -9999em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #636363;
  background: -moz-linear-gradient(left, #636363 10%, rgba(99, 99, 99, 0) 42%);
  background: -webkit-linear-gradient(
    left,
    #636363 10%,
    rgba(99, 99, 99, 0) 42%
  );
  background: -o-linear-gradient(left, #636363 10%, rgba(99, 99, 99, 0) 42%);
  background: -ms-linear-gradient(left, #636363 10%, rgba(99, 99, 99, 0) 42%);
  background: linear-gradient(to right, #636363 10%, rgba(99, 99, 99, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #636363;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.loader:after {
  background: #ecf0f1;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.trigger-dropdown {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

#test-menu {
  z-index: 2;
}

/* End Sandbox */

label.plaintext {
  font-weight: normal;
}

/* Minform */
.minform label {
  text-transform: uppercase;
  color: #5d5d5d;
  font-size: 11px;
  display: block;
  /* margin-top: 25px; */
  margin-left: 1px;
  margin-bottom: 3px;
}

.minform label span {
  color: #5d5d5d;
}

.minform p {
  color: #5d5d5d;
  font-size: 12px;
}

.minform input {
  border: 0;
  border-bottom: 1px solid #efefef;
  border-radius: 0px;
}

input.readonly {
  width: 100%;
  background-color: "transparent";
  padding: 7px;
  border: 0;
  font-family: monospace;
  color: #aaa;
  font-size: 12px;
}

.email-editor {
  position: relative;
  padding-bottom: 32px;
  z-index: 1;
}

.minform div.form-group .field {
  margin-bottom: 0px;
}

.ql-bubble * {
  z-index: 999;
}

/* Editor options cut off by iframe. Hiding as its not a necessary feature */
.ql-tooltip {
  display: none !important;
}

.ql-editor {
  min-height: 165px;
}

.mailbot-menu a {
  text-decoration: none;
  white-space: nowrap;
}
.mailbot-submenu .active {
  font-weight: bold;
  background-color: transparent;
  text-decoration: none;
}

.mailbot-submenu a:hover {
  text-decoration: none;
}

.mailbot-submenu {
  list-style: none;
  margin-left: -30px;
  margin-bottom: 10px;
  margin-top: 3px;
}

.mailbot-submenu li {
  margin: 7px 0;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

/** react-jsonform-schema uses this to style headers **/
legend#root__title {
  font-size: 32px;
}

.markdown-container p,
.markdown-container ul,
.markdown-container li,
.markdown-container div,
.markdown-container table {
  line-height: 30px;
  font-size: 18px;
}

.markdown-container p {
  margin-bottom: 10px;
}

.markdown-container h2,
.markdown-container h3,
.markdown-container h4,
.markdown-container h5 {
  margin-top: 40px;
}

.markdown-container img {
  display: block;
  max-width: 100%;
  height: auto;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #f8f8f8;
  border: 0;
}

.golden-ratio-16by9 {
  padding-bottom: 56.25% !important;
}

.task-menu-actions__buttons {
  margin-top: 60px;
  z-index: 2; /* position fixed creates stacking context. This div renders modals, to get over nav, needs z-index: 2 */
  max-width: 300px;
}

@media screen and (max-width: 767px) {
  .task-menu-actions {
    display: flex;
    flex-direction: column-reverse;
    max-width: 625px !important;
  }
  .task-menu-actions__buttons {
    margin-top: 0px;
    max-width: initial;
    position: relative;
    display: flex;
    flex-flow: column;
  }

  .task-menu-actions__edit-delete-group {
    text-align: right;
    width: 180px;
    margin-left: auto;
    margin-bottom: -50px;
  }
}

/* User Stats */

tr.user-stats-header th {
  white-space: nowrap;
}

/* Bot Templates */

.bot-templates {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: -15px;
}

.bot-templates .bot-template {
  width: 300px;
  height: 240px;
  padding: 30px;
}

/** Tour **/

.modal-tour p {
  font-size: 21px;
  text-align: center;
  margin-bottom: 3px;
}

.modal-tour p.caption {
  font-size: 14px;
  color: #333;
}

.modal-tour h2 {
  font-family: "Lora", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.modal-tour h1 {
  font-family: "Lora", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.modal-tour h1.large {
  margin-top: 10%;
  line-height: 60px;
  margin-bottom: 50px;
  font-size: 70px;
}

/** People **/
.person-attributes-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 0.4fr));
  /* grid-gap: 20px; */
  justify-items: left;
  align-items: left;
}

/** Simplified Single-Column Layout **/
.central-content {
  max-width: 1200px;
  width: 100%;
}

/** Login Container */

.login-container-logo {
  margin: 6vh;
  width: 150px;
}

@media (max-width: 767px) {
  .main-panel {
    padding: 30px 25px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .main-panel.email-message {
    padding: 30px 5px !important;
  }

  .login-container-logo {
    margin: 2vh;
    width: 120px;
  }
}

/** new Layout components in Layout.jsx */
@media (max-width: 767px) {
  .layout-container {
    flex-direction: column-reverse;
  }

  .layout-right-menu {
    width: calc(100% - 2px);
    display: flex;
    flex-direction: row-reverse;
    margin: 0 0 -60px 0 !important;
  }

  .layout-right-menu-contents {
    position: static !important;
    width: 95% !important;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
  }

  .back-link {
    position: relative;
    top: -23px;
  }
}

a.muted-link {
  color: #aaa;
  transition: color 200ms;
}

a.muted-link:hover {
  color: #999;
  text-decoration: none;
}

/** Create FUT + Create Person Links */
a.generic-create-link {
  margin: 9px 5px 19px 45px;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  a.generic-create-link {
    margin: 9px 5px 19px 23px;
    text-decoration: none;
    position: absolute;
    left: 80px;
  }
}

/** Create FUT + Create Person Grey Button Links */
a.generic-create-link-grey {
  margin: 0px 5px 0px 15px;
  text-decoration: none;
  padding: 12px 20px;
  color: #6b6b6b;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 1px 2px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  transition: background-color 200ms, box-shadow 500ms;
  height: 35px;
}

a.generic-create-link-grey:hover {
  box-shadow: 2px 2px 8px #ccc;
}

a.generic-create-link-grey:active {
  background-color: #ececec !important;
  box-shadow: 3px 4px 7px #ececec !important;
}

@media screen and (max-width: 767px) {
  a.generic-create-link-grey,
  a.generic-create-link-grey:hover,
  a.generic-create-link-grey:active {
    margin: -3px 3px 0px 0px;
    position: absolute;
    left: 80px;
    background-color: initial;
    box-shadow: none;
  }
}

/**
* Experimental Glitch-Style Link
**/
a.proto-button {
  background-color: white;
  padding: 2px 25px;
  box-shadow: 4px 3px #a9ccf9;
  /* box-shadow: 2px 2px #333; */
  border-radius: 5px;
  border: 2px solid #333;
  color: #333;
  /* font-weight: bold; */
  height: 28px;
  margin: 6px 0 0 20px;
  transition: margin 150ms ease-out, box-shadow 150ms ease-out;
  text-decoration: none;
}

a.proto-button:hover,
a.proto-button:focus {
  box-shadow: 4px 3px #a9ccf9;
  /* box-shadow: 3px 3px #333; */
  margin: 5px 0 0 19px;
}

a.proto-button:active {
  margin: 6px 0 0 20px !important;
  box-shadow: 0 !important;
}

/* Upgrade Dialogs */

.highlight {
  background-color: #e3f8ff;
}

.info-dialog h2 {
  font-size: 18px;
  font-weight: bold;
}

.info-dialog p {
  color: #676767;
  font-size: 14px;
  margin-right: 10px;
}

.app-section {
  margin: auto;
}

.popover-link {
  color: #11a2d2;
  cursor: pointer;
}

/* Pricing Dialog */

.serif {
  font-family: "Lora", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.per-mo {
  font-family: Avenir;
  font-size: 20px;
  letter-spacing: -1px;
  color: #6c91a1;
}

.blue-accent::before {
  content: "";
  width: 40px;
  height: 4px;
  background-color: #11a2d2;
  border-radius: 4px;
  display: block;
  margin-top: 2px;
  margin-bottom: 30px;
}

.feature-walk {
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 100px 20px;
}

.feature-walk .tell {
  flex: 1;
  text-align: left;
}

.feature-walk .show {
  flex: 1;
}

.feature-walk .show > img {
  width: 150px;
  margin: 5px;
}

.feature-walk.reverse {
  flex-direction: row-reverse;
}

@media (max-width: 767px) {
  .feature-walk {
    flex-direction: column-reverse;
  }

  .feature-walk h2 {
    font-size: 28px;
  }

  .upgrade-modal {
    width: 95vw;
  }
}

code {
  padding: 0 4px;
}
/* Ant Autocomplete */

.minform .ant-select-customize-input input,
.minform .ant-select-selection-placeholder {
  height: 43px !important;
  line-height: 4.2rem !important;
  padding: 3px 10px;
}

/* .minform .ant-select-customize-input input:hover {
  cursor: pointer;
}

.minform .ant-select-customize-input input:focus {
  background-color: white;
  border: 1px solid #aaa;
} */

.minform .ant-select-customize-input.ant-select-auto-complete {
  width: 100%;
}

.minform .ant-select-customize-input input:focus-visible {
  border: 0;
  outline: none;
  box-shadow: none;
}

/* Task History */
.delivery-log-item:hover {
  cursor: pointer;
  background-color: #fafafa;
}

.ant-message-notice-content {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
